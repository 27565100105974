import { render, staticRenderFns } from "./index.vue?vue&type=template&id=974f6452&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./style.scss?vue&type=style&index=0&id=974f6452&prod&lang=scss&scoped=true&external"
import style1 from "./index.vue?vue&type=style&index=1&id=974f6452&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "974f6452",
  null
  
)

export default component.exports